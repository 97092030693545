import React from "react";

import { createSettingsView, ViewComponent } from "@settings/view";

import { CompanyAnalyticsProvider, CompanyAnalyticsDisplay } from "./_lib";

const CompanyAnalytics: ViewComponent = () => {
	return (
		<CompanyAnalyticsProvider>
			<CompanyAnalyticsDisplay />
		</CompanyAnalyticsProvider>
	);
};

export default createSettingsView(CompanyAnalytics, {
	title: "Analytics",
	internalPad: true,
	internalScroll: true,
});
