import React from "react";
import {
	UpdateCompanyAnalyticsForm,
	IUpdateCompanyAnalyticsFormProps,
} from "~/views/settings/analytics-tracking/_lib/UpdateCompanyAnalytics/UpdateCompanyAnalyticsForm";

export type AnalyticsUpdateProps = IUpdateCompanyAnalyticsFormProps;

export const AnalyticsUpdate: React.FC<IUpdateCompanyAnalyticsFormProps> = (
	props,
) => {
	return <UpdateCompanyAnalyticsForm {...props} />;
};
