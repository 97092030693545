import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import cx from "clsx";

import { ControlButton } from "~/components/ControlButton";
import { useDialog, DialogTypes } from "@hooks/use-dialog";

import { AnalyticsFields, useCompanyAnalyticsContext } from "./context";

import Edit from "~/assets/vectors/edit-2.svg";
import Delete from "~/assets/vectors/trash.svg";
import { Typography } from "~/components/Typography";
import { AnalyticsValues } from "@api/companies";
import { AnalyticsType } from "@graphql/types";
import { getAnalyticsIdLabel } from "./label";

const useStyles = makeStyles((theme) => ({
	rowContainer: {
		display: "flex",
		flexFlow: "row nowrap",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			overflow: "auto",
		},
	},
	rowValueContainer: {
		minWidth: "250px",
		width: "400px",
		[theme.breakpoints.up(theme.breakpoints.values.md)]: {
			display: "flex",
			gap: 8,
		},
	},
	rowValue: {
		fontWeight: 800,
	},
	actionContainer: {
		display: "flex",
		gap: 4,
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			display: "none",
		},
	},
	action: {
		padding: 5,
	},
	spinnerContainer: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		display: "flex",
		flexFlow: "row nowrap",
		justifyContent: "center",
		alignItems: "center",
	},
	spinner: {
		color: theme.palette.background.secondary.main,
	},
}));

interface IAnalyticsRowProps {
	type: AnalyticsType;
	companyAnalyticId: number;
	fields: AnalyticsFields;
	values: AnalyticsValues;
	readOnly: boolean;
	loading: boolean;
}

export const AnalyticsRow: React.FC<IAnalyticsRowProps> = ({
	type,
	companyAnalyticId,
	fields,
	values,
	readOnly,
	loading,
}) => {
	const {
		updateCompanyAnalytic,
		removeCompanyAnalytic,
		showModal,
		setLoading,
	} = useCompanyAnalyticsContext();
	const { dialog, showDialog } = useDialog();
	const styles = useStyles();

	const doUpdate = () => {
		const oldValues = values;
		showModal({
			label: "Change Company Analytics",
			action: "Update Analytics Pixel",
			fields,
			values: {
				analyticsType: type,
				analyticsId: values.analyticsId,
				analyticsSecret: values.analyticsSecret || "",
				domain: values.domain || "",
			},
			onSubmit: async (
				newAnalyticsId: string,
				newAnalyticsSecret: string,
				newDomain?: string,
			) => {
				await updateCompanyAnalytic(type, oldValues, {
					analyticsId: newAnalyticsId || values.analyticsId,
					analyticsSecret: newAnalyticsSecret,
					domain: newDomain,
				});
			},
		});
	};
	const doDelete = () => {
		showDialog({
			type: DialogTypes.ContinueCancel,
			title: "Delete Analytics Pixel",
			message: `Are you sure you want to delete Analytic ID: ${values.analyticsId}?`,
			action: () => {
				companyAnalyticId &&
					values.analyticsId &&
					type &&
					setLoading(
						type,
						values.analyticsId,
						values.analyticsSecret || "",
						values.domain || "",
						() => removeCompanyAnalytic(companyAnalyticId),
					);
			},
		});
	};

	return (
		<Box className={styles.rowContainer}>
			<Box className={styles.rowValueContainer}>
				<span>{getAnalyticsIdLabel(type)}:</span>
				<Typography className={styles.rowValue}>
					{values.analyticsId}
				</Typography>
			</Box>
			<Box className={styles.rowValueContainer}>
				Analytics Secret:
				<Typography className={styles.rowValue}>
					{values.analyticsSecret || "N/A"}
				</Typography>
			</Box>
			<Box className={styles.rowValueContainer}>
				Domain:
				<Typography className={styles.rowValue}>
					{values.domain || "N/A"}
				</Typography>
			</Box>
			<Box className={styles.actionContainer}>
				{loading && (
					<Box className={styles.spinnerContainer}>
						<CircularProgress
							size="2rem"
							className={cx(styles.action)}
							classes={{
								root: styles.spinner,
							}}
						/>
					</Box>
				)}
				<ControlButton
					icon={<Edit />}
					color="black"
					readOnly={readOnly}
					onClick={doUpdate}
					visible={!loading}
				/>
				<ControlButton
					icon={<Delete />}
					color="red"
					readOnly={readOnly}
					onClick={doDelete}
					visible={!loading}
				/>
			</Box>
			{dialog}
		</Box>
	);
};
