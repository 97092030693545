import React, { Fragment, useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import CompanyPanel from "~/components/CompanyPanel";
import { InfoCardTableRow } from "~/components/InfoCard";
import { GlobalLoader } from "@settings/GlobalLoader";
import { AutocorpBlock } from "@settings/AutocorpBlock";
import { CriticalError } from "@settings/CriticalError";

import { useAuth } from "@api/auth";

import { AnalyticsTitle } from "./AnalyticsTitle";
import { AnalyticsRow } from "./AnalyticsRow";
import { AnalyticsEmpty } from "./AnalyticsEmpty";
import {
	useCompanyAnalyticsContext,
	typeCategories,
	defaultEmptyMessage,
} from "./context";

const useStyles = makeStyles(({ dashboard }) => ({
	container: {
		display: "flex",
		flexFlow: "row nowrap",
		height: "100%",
	},
	content: {
		padding: dashboard?.innerContent?.paddingTop,
		paddingBottom: 0,
		overflow: "auto",
		height: "max-content",
		maxHeight: "100%",
		width: "100%",
		margin: 0,
		position: "relative",
	},
	table: {
		overflow: "hidden",
	},
	tableRow: {
		paddingTop: 5,
		paddingBottom: 5,
	},
}));

const AnalyticsContent: React.FC = () => {
	const { getAnalyticsDisplay } = useCompanyAnalyticsContext();
	const styles = useStyles();

	return (
		<Fragment>
			{typeCategories.map((category, i) => {
				const analyticsDisplay = getAnalyticsDisplay(category);
				const hasContent = analyticsDisplay.values.length > 0;
				const analyticsFields = category.fields;

				return (
					<Grid
						key={`analytic-type-category-${category.type}`}
						item
						xs={12}
					>
						<InfoCardTableRow
							classes={{
								body: styles.table,
								row: styles.tableRow,
							}}
							title={
								<AnalyticsTitle
									title={category.title}
									type={category.type}
									fields={analyticsFields}
									readOnly={analyticsDisplay.readOnly}
								/>
							}
							columns={[
								{
									width: "100%",
								},
							]}
							rows={
								!hasContent
									? [
											{
												cells: [
													<AnalyticsEmpty
														key={`category-${category.type}-empty`}
														message={
															category.empty ||
															defaultEmptyMessage(
																category.type,
															)
														}
													/>,
												],
											},
									  ]
									: analyticsDisplay.values.map(
											(value, i) => ({
												cells: [
													<AnalyticsRow
														key={`category-${category.type}-value-${value}-${i}`}
														type={category.type}
														companyAnalyticId={
															value.id
														}
														fields={analyticsFields}
														values={{
															analyticsId:
																value.analyticsId,
															analyticsSecret:
																value.analyticsSecret,
															domain: value.domain,
														}}
														readOnly={
															analyticsDisplay.readOnly
														}
														loading={value.loading}
													/>,
												],
											}),
									  )
							}
						/>
					</Grid>
				);
			})}
		</Fragment>
	);
};

export const CompanyAnalyticsDisplay: React.FC = () => {
	const styles = useStyles();
	const { loading, error } = useCompanyAnalyticsContext();
	const { user, selectedCompany } = useAuth();

	const content = useMemo(() => {
		if (user?.autocorpAdmin && !selectedCompany) {
			return <AutocorpBlock />;
		}

		let content: React.ReactNode;

		if (error) {
			content = (
				<CriticalError
					message={"Unable to retrieve company analytics!"}
					error={error}
				/>
			);
		} else if (loading) {
			content = <GlobalLoader />;
		} else {
			content = <AnalyticsContent />;
		}

		return (
			<Grid
				container
				spacing={2}
				className={styles.content}
				style={{
					height: loading ? "100%" : undefined,
				}}
			>
				{content}
			</Grid>
		);
	}, [loading, selectedCompany, styles, user, error]);

	return (
		<Box className={styles.container}>
			{(user?.companies || []).length > 1 && (
				<CompanyPanel placement="inline" />
			)}
			{content}
		</Box>
	);
};
