import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
	action: {
		padding: 5,
	},
}));

interface IControlButton {
	icon: React.ReactElement;
	color?: string;
	readOnly: boolean;
	onClick: () => void;
	visible: boolean;
}

export const ControlButton: React.FC<IControlButton> = ({
	icon,
	readOnly,
	color,
	onClick,
	visible = true,
}) => {
	const styles = useStyles();

	return (
		<IconButton
			className={styles.action}
			disabled={readOnly}
			style={{
				color,
				visibility: !visible || readOnly ? "hidden" : undefined,
				// width: !visible ? "0px" : undefined,
			}}
			onClick={onClick}
		>
			{icon}
		</IconButton>
	);
};
