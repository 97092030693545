import React, {
	createContext,
	useContext,
	useMemo,
	useCallback,
	useState,
} from "react";

import { AnalyticsValues, useCompanyAnalytics } from "@api/companies";
import { useAuth } from "@api/auth";
import { useViewContext } from "~/state";
import { CompanyAnalytic as RawCompanyAnalytic } from "~/model/companies";

import { AnalyticsUpdate, AnalyticsUpdateProps } from "./AnalyticsUpdate";
import { AnalyticsType } from "@graphql/types";

type CompanyAnalyticsContext = ReturnType<
	typeof useCompanyAnalyticsContextValue
>;
type CompanyAnalytic = RawCompanyAnalytic & {
	fields: AnalyticsFields;
	values: AnalyticObj[];
	readOnly: boolean;
};
type CompanyAnalyticsTypeIndex = Record<AnalyticsType, CompanyAnalytic>;

type UpdateModalProps = Omit<AnalyticsUpdateProps, "onComplete">;

type AnalyticObj = {
	id: number;
	analyticsId: string;
	analyticsSecret?: string;
	domain?: string;
};
type CompanyAnalyticsTypeLoadingIndex = {
	[type in AnalyticsType]?: AnalyticObj[];
};

export type AnalyticsFields = Array<keyof AnalyticsValues>;

export type CompanyAnalyticsTypeCategory = {
	title: string;
	type: AnalyticsType;
	empty?: string;
	forceReadOnly: boolean;
	fields: AnalyticsFields;
};

export type CompanyAnalyticsType = AnalyticObj & {
	loading: boolean;
};

export type CompanyAnalyticsTypeDisplay = {
	readOnly: boolean;
	fields: AnalyticsFields;
	values: CompanyAnalyticsType[];
};

const CompanyAnalyticsContext = createContext<CompanyAnalyticsContext>(
	{} as CompanyAnalyticsContext,
);

export const useCompanyAnalyticsContext = () => {
	const context = useContext(CompanyAnalyticsContext);
	if (!context) {
		throw new Error(
			"You cannot use CompanyAnalytics from outside of its Provider",
		);
	}

	return context;
};

export const defaultEmptyMessage = (type: string) =>
	`You do not have any company analytics`;

export const typeCategories: CompanyAnalyticsTypeCategory[] = [
	{
		title: "Google A4",
		type: AnalyticsType.GoogleA4,
		forceReadOnly: false,
		fields: ["analyticsId", "analyticsSecret", "domain"],
	},
	{
		title: "Meta Pixel",
		type: AnalyticsType.MetaPixel,
		forceReadOnly: false,
		fields: ["analyticsId", "analyticsSecret", "domain"],
	},
];

const useCompanyAnalyticsContextValue = () => {
	const queryMethods = useCompanyAnalytics();
	const [typesLoading, setTypesLoading] =
		useState<CompanyAnalyticsTypeLoadingIndex>({});
	const {
		actions: { openModal: showLayoutModal, closeModal: closeLayoutModal },
	} = useViewContext();
	const { user } = useAuth();

	const companyAnalytics = useMemo(() => {
		const typeIndex = typeCategories.reduce((acc, category) => {
			const analyticsFields = category.fields.includes("analyticsId")
				? category.fields
				: ([...category.fields, "analyticsId"] as AnalyticsFields);
			const analytics = queryMethods.companyAnalytics?.filter(
				(analytic) => analytic.type === category.type,
			);

			return Object.assign(acc, {
				[category.type]: {
					// ...(analytic || {}),
					fields: analyticsFields,
					values: (analytics as AnalyticObj[]) || [],
					readOnly:
						category.forceReadOnly ||
						!user?.selectedCompany ||
						// TODO: Update granular permission set
						(!user?.autocorpAdmin && !user?.managesPeople),
				} as CompanyAnalytic,
			});
		}, {} as CompanyAnalyticsTypeIndex);
		return typeIndex;
	}, [queryMethods, user]);

	const showModal = useCallback(
		(props: UpdateModalProps) => {
			showLayoutModal({
				content: (
					<AnalyticsUpdate {...props} onComplete={closeLayoutModal} />
				),
				props: {
					title: props.action,
				},
			});
		},
		[showLayoutModal, closeLayoutModal],
	);

	const setLoading = useCallback(
		(
			type: AnalyticsType,
			analyticsId: string,
			analyticsSecret: string,
			domain: string,
			cb: () => Promise<void>,
		) => {
			setTypesLoading((curValue) => {
				const index = (curValue[type] = curValue[type] || []);
				index!.push({
					analyticsId,
					analyticsSecret,
					domain,
				} as AnalyticObj);
				return { ...curValue };
			});
			cb().finally(() =>
				setTypesLoading((curValue) => {
					curValue[type] = (curValue[type] || []).filter(
						(analyticObj) =>
							analyticObj.analyticsId !== analyticsId &&
							analyticObj.analyticsSecret !== analyticsSecret &&
							analyticObj.domain !== domain,
					);
					return { ...curValue };
				}),
			);
		},
		[],
	);

	const getAnalyticsDisplay = useCallback(
		(
			category: CompanyAnalyticsTypeCategory,
		): CompanyAnalyticsTypeDisplay => {
			const analytic = companyAnalytics[category.type];
			if (analytic) {
				const loadingIndex =
					(analytic.type && typesLoading[analytic.type]) || [];
				const fields = analytic.fields;
				const values = analytic.values || [];

				return {
					readOnly: analytic.readOnly,
					fields,
					values: values.map((v) => ({
						id: v.id,
						analyticsId: v.analyticsId,
						analyticsSecret: v.analyticsSecret,
						domain: v.domain,
						loading: loadingIndex.includes(v),
					})),
				};
			}
			return {
				readOnly: false,
				fields: [],
				values: [],
			};
		},
		[companyAnalytics, typesLoading],
	);

	const value = useMemo(
		() => ({
			...queryMethods,
			companyAnalytics,
			showModal,
			getAnalyticsDisplay,
			setLoading,
		}),
		[
			queryMethods,
			companyAnalytics,
			showModal,
			getAnalyticsDisplay,
			setLoading,
		],
	);

	return value;
};

export const CompanyAnalyticsProvider: React.FC = ({ children }) => {
	const contextValue = useCompanyAnalyticsContextValue();

	return (
		<CompanyAnalyticsContext.Provider value={contextValue}>
			{children}
		</CompanyAnalyticsContext.Provider>
	);
};
