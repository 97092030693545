import React from "react";

import { Typography } from "~/components/Typography";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
	rowContainer: {
		display: "flex",
		flexFlow: "row nowrap",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		wordBreak: "break-word",
	},
}));

interface IAnalyticsEmptyProps {
	message: string;
}

export const AnalyticsEmpty: React.FC<IAnalyticsEmptyProps> = ({ message }) => {
	const styles = useStyles();

	return (
		<Box className={styles.rowContainer}>
			<Typography variant="h4">{message}</Typography>
		</Box>
	);
};
